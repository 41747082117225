/*eslint-disable */
import pdfViewer from './pdf-viewer';
import {bus} from '@/main';  
export default {
  name: 'pre-fo-doctor',
  components: {
    'pdf-viewer' : pdfViewer
  },
  props: ['doctorId'],
  data () {
    return {
      prescriptionListData:null,
      prescriptionListResults:[],
      pdfViewerPopup:false,
      pdfViewerFileName:'',
      pdfViewerUrl:''
    }
  },
  computed: {

  },
  mounted () {
    this.getPrescriptionListData();
    bus.$on('pdfViewerPopupBus', (data) => {
      this.pdfViewerPopup = data;
    });
    bus.$on('patientPrescriptionBus', (data) => {
      this.getPrescriptionListData()
    });
  },
  methods: {
    getPrescriptionListData(){
      this.$store.dispatch('getPrescriptionListApiFun', {id:this.doctorId}).then((res)=>{
        this.prescriptionListData = res.data.data
        this.prescriptionListResults = res.data.data.data
      })
    },
    pdfViewerFun(url){
      var getName = url.split("?")[0].split("/")
      const fileName = getName[getName.length - 1]
      this.pdfViewerFileName = fileName
      this.pdfViewerUrl = url
      this.pdfViewerPopup = true
      document.body.classList.add('pdf_view_actv')
    },
  }
}


